<template>
  <div class="container">
    <div class="divide"></div>
    <div class="title">
      <!-- <div style="width: 50%;">
        <img style="width: 100%;" src="../assets/cases/whgg.png" alt="">
      </div> -->
      <div class="videos">
        <div class="imgs">
          <img class="bg" :src="imgSrc" alt="" />
        </div>
        <div :class="indexVideo==5?'imgName imgcolor':'imgName' ">{{ imgName }}</div>
        <!-- 底部图片集 -->
        <div class="cases-video">
          <div class="nav-bar">
            <div class="img-list">
              <div
                v-for="(item, i) in arrayImgWithIndex.slice(
                  startVideo,
                  endVideo
                )"
                :key="i"
                @click="toggleVideo(item.index)"
                class="img-shell"
              >
                <img
                  :class="
                    item.index == indexVideo ? 'top-img actived' : 'top-img'
                  "
                  :src="item.item.path"
                  alt=""
                />
              </div>
              <img
                @click="backVideo"
                class="left"
                src="../assets/hall/jt1.png"
                alt=""
              />
              <img
                @click="forwardVideo"
                class="right"
                src="../assets/hall/jt2.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>

      <div class="text">
        <div>重要工程业绩</div>
        <div>
          <span style="display: inline-block; width: 0.33rem"></span>
          在过去的几十年里，我们团队致力于为客户提供卓越的工程服务，并取得了丰硕的业绩。今天，
          我们将为您展示我们在各领域的杰出项目和成果，证明我们始终致力于卓越工程品质的承诺。
          在这一段时间的努力和协作下，我们成功地与多家产业和公司达成了合作。通过这次的实践，
          我们不仅在专业技能上有了更深刻的认识和提高，还进一步优化了工程管理流程。
          我们坚信，在未来的日子里，我们的工程质量会进一步提高，我们会对每一项工程都进行
          严格的把关，让每一个项目都达到优质水平
        </div>
      </div>
    </div>
    <div class="case">
      <div class="title">客户工程案例</div>
      <div class="line"></div>
      <div class="en-title">Customer Engineering Cases</div>
      <div class="cases-content">
        <div class="nav-bar">
          <div class="img-list">
            <div
              v-for="(item, i) in arrayWithIndex.slice(start, end)"
              :key="i"
              @click="toggleScene(i)"
            >
              <img class="top-img" :src="item.item.projectImage" alt="" />
              <div
                :class="
                  item.index === index ? 'img-title title-actived' : 'img-title'
                "
              >
                <div class="intro-title">{{ item.item.projectName }}</div>
                <div class="intro-content">
                  {{ item.item.projectDescription }}
                </div>
                <div class="intro-enter">
                  <div
                    :class="item.index == index ? 'btnactive' : ''"
                    @click="viewCase(item.item)"
                  >
                    阅读他们的案例 >
                  </div>
                </div>
              </div>
            </div>
            <div class="arrow left" @click="back">
              <span></span>
            </div>
            <div class="arrow right" @click="forward">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// 在页面加载完成后执行
window.onload = function () {
  // 将滚动条置于顶部
  setTimeout(function () {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, 100);
};
export default {
  data() {
    return {
      cases: [
        {
          title: "客户案例标题",
          content:
            "操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操客操似文学述客操似文学述客操似文学述客操",
        },
      ],
      isPause: true,
      indexVideo: 0,
      startVideo: 0,
      endVideo: 4,
      index: 0,
      start: 0,
      end: 3,
      // 图片名字
      imgName: "阿里巴巴达摩院",
      // 选中图片
      imgSrc: "https://oss.zijingshuke.com/1706688474320.png",
      // 顶部工程业绩图片
      topImgList: [
        {
          path: "https://oss.zijingshuke.com/1706688474320.png",
          name: "阿里巴巴达摩院",
        },

        {
          path: "https://oss.zijingshuke.com/1706766105702.png",
          name: "阿里巴巴淘宝城",
        },
        {
          path: "https://oss.zijingshuke.com/1706766178148.png",
          name: "阿里巴巴西溪园区",
        },
        {
          path: "https://oss.zijingshuke.com/1706689710971.png",
          name: "海康威视产业园",
          // video:require('../assets/5.mp4')
        },
        {
          path: "https://oss.zijingshuke.com/1706766160506.png",
          name: "杭州绿城育华学校",
        },
        {
          path: "https://oss.zijingshuke.com/1706766234078.png",
          name: "杭州市钱江新城（中国人寿大厦）",
        },
        {
          path: "https://oss.zijingshuke.com/1706766285530.png",
          name: "杭州西湖文化广场",
        },
        {
          path: "https://oss.zijingshuke.com/1706690297204.png",
          name: "联合国全球信息管理论坛（西半球）",
          // video:require('../assets/5.mp4')
        },
        {
          path: "https://oss.zijingshuke.com/1706766238039.png",
          name: "天津大学新校区",
        },
        {
          path: "https://oss.zijingshuke.com/1706766307627.png",
          name: "萧山国际机场",
        },
        {
          path: "https://oss.zijingshuke.com/1706766309751.png",
          name: "浙大国际科创中心",
        },
        {
          path: "https://oss.zijingshuke.com/1706766349525.png",
          name: "浙江大学海宁国际校区",
        },
        {
          path: "https://oss.zijingshuke.com/1706766303578.png",
          name: "浙江工业大学德清校区",
        },
        {
          path: "https://oss.zijingshuke.com/1706766370925.png",
          name: "浙江湖畔大学创业研究中心",
        },
        {
          path: "https://oss.zijingshuke.com/1706766397228.png",
          name: "浙江绿城医院",
        },
        {
          path: "https://oss.zijingshuke.com/1706766404332.png",
          name: "中大银泰城",
        },
        {
          path: "https://oss.zijingshuke.com/1706766410130.png",
          name: "中国光谷数字经济产业园项目西地块一期工程",
        },
      ],
      imgList: [
        {
          path: require("../assets/cases/t10.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content:
              "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
        {
          path: require("../assets/cases/t11.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content:
              "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
        {
          path: require("../assets/cases/t112.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content:
              "项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
        {
          path: require("../assets/hall/cq4.png"),
          intro: {
            title: "合作公司名称及其项目名称",
            content: "项目内容项目内容项目内容项目内容项目内容",
            path: "/custom",
          },
        },
      ],
      // 工程案例数组
      projectList: [],
    };
  },
  computed: {
    arrayWithIndex() {
      return this.projectList.map((item, index) => {
        return { index, item };
      });
    },
    arrayImgWithIndex() {
      return this.topImgList.map((item, index) => {
        return { index, item };
      });
    },
  },

  methods: {
    // 请求客户案例列表
    requestCustomList() {
      let param = {
        pageNum: 1,
        pageSize: 100,
      };
      this.$post("/web/cooperationCase/pageList", param).then((res) => {
        // 主要参数
        if (res.code == 1) {
          // console.log(res.data);
          this.projectList = res.data.list;
        } else {
          console.log("异常");
        }
      });
    },
    toggleScene(i) {
      this.index = i;
    },
    // 顶部图片集选中
    toggleVideo(i) {
      this.indexVideo = i;
      console.log(i);
      this.imgName = this.topImgList[this.indexVideo].name;
      this.imgSrc = this.topImgList[this.indexVideo].path;
    },
    // 顶部图片集下一个
    forwardVideo() {
      if (this.indexVideo < 3) {
        this.indexVideo++;
      } else {
        if (this.endVideo < this.topImgList.length) {
          this.startVideo++;
          this.endVideo++;
          this.indexVideo++;
          // this.indexVideo = 3
        }
      }
      this.imgName = this.topImgList[this.indexVideo].name;
      this.imgSrc = this.topImgList[this.indexVideo].path;
      // console.log("start" + this.startVideo);
      // console.log("end" + this.endVideo);
      // console.log(this.indexVideo);
    },
    // 顶部图片集上一个
    backVideo() {
      if (this.indexVideo > 0) {
        if (this.startVideo > 0) {
          this.startVideo--;
          this.endVideo--;
          this.indexVideo--;
        } else {
          this.indexVideo--;
        }
      }
      this.imgName = this.topImgList[this.indexVideo].name;
      this.imgSrc = this.topImgList[this.indexVideo].path;
      // console.log(this.indexVideo);
    },
    // 客户案例下一个
    forward() {
      if (this.index < 2) {
        this.index++;
      } else {
        console.log(this.projectList.length);
        if (this.end < this.projectList.length) {
          this.start++;
          this.end++;
          this.index++;
        }
      }
      // console.log("start:" + this.start);
      // console.log("end:" + this.end);
    },
    // 客户案例上一个
    back() {
      if (this.index > 0) {
        if (this.start > 0) {
          this.start--;
          this.end--;
          this.index--;
        } else {
          this.index--;
        }
        // console.log("start:" + this.start);
        // console.log("end:" + this.end);
      }
    },

    // 跳转到客户案例详情
    viewCase(item) {
      this.$router.push("/custom");
      localStorage.setItem("customDetail", JSON.stringify(item));
    },
  },
  mounted() {
    this.requestCustomList();
  },
};
</script>

<style scoped lang="scss">
.container > div {
  margin: 0 1.6rem;
}
.container .divide {
  height: 0.8rem;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  margin: 0;
}
.container > .title {
  display: flex;
  justify-content: space-between;
  /* align-items: center; */
  margin: 0.78rem 1.5rem 1.02rem;
  height: 5.7rem;
  overflow: auto;
 
}
/* 顶部工程业绩图 */
.videos {
  position: relative;
  width: 8.5rem;
  height: 5.7rem;
  .imgs {
    width: 8.5rem;
    height: 5.5rem;
    // 选中图片展示
    .bg {
      width: 8.5rem;
      height: 100%;
      object-fit: cover;  /* 保持宽高比不变，图片可能被裁剪 */
      // object-fit: contain; 
      // object-position: top;
    }
  }
  // 图片名
  .imgName {
    position: absolute;
    top: 0.2rem;
    color: #fff;
    font-weight: blod;
    width: 8.5rem;
    text-align: center;
    font-size: 0.3rem;
    cursor: pointer;
  }
  .imgcolor{
    color: #000;
  }
  // 底部图片集
  .cases-video {
    position: absolute;
    bottom: 0.5rem;
    width: 8.5rem;
    height: 0.86rem;
    .nav-bar {
      height: 100%;
    }
  }
}

.text {
  width: 38vw;
  margin-left: 0.77rem;
  margin-top: 10px;
}
.text > div:nth-of-type(1) {
  font-family: PingFangSC-Medium, PingFang SC;
  font-size: 0.35rem;
  line-height: 24px;
  font-weight: 500;
  color: #272727;
  margin-bottom: 0.28rem;
  cursor: pointer;
}
.text > div:nth-of-type(2) {
  font-size: 0.25rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #a0a0a0;
  line-height: 36px;
  cursor: pointer;
}
.case .title {
  font-size: 0.32rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: #272727;
  cursor: pointer;
}
.case .en-title {
  font-size: 0.26rem;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #909090;
  margin-bottom: 0.58rem;
  cursor: pointer;
}
.line {
  width: 2.5vw;
  height: 3px;
  background: #127bf2;
  border-radius: 2px;
  margin: 10px 0;
}

.cases-content {
  position: relative;
  height: 5.68rem;
  margin-bottom: 1rem;
}
.nav-bar {
  width: 100%;
  height: 100%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
}
/* .cases-content .nav-bar {
    height: 100%;
} */
.img-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 85%;
  height: 100%;
  overflow: hidden;
}
.img-list > div {
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 4.4rem;
  height: 100%;
  display: flex;
}
.img-list > .img-shell {
  width: 1.5rem;
  height: 0.86rem;
  padding-right: 0.2rem;
  position: relative;
}
.img-list > .img-shell .top-img {
  height: 100%;
  filter: brightness(0.5);
}
.img-list > .img-shell .actived {
  filter: brightness(1);
}
.top-img {
  width: 100%;
  height: 50%;
}
.img-title {
  width: 100%;
  height: 50%;
  text-align: center;
  line-height: 220%;
  background-color: #fff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  border: 1px solid #e7e7e7;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
}
.img-title:hover {
  color: #fff;
  background-color: #127bf2;
}
.title-actived {
  color: #fff;
  background-color: #127bf2;
}
.title-actived .intro-content {
  color: #fff;
}
.img-list .arrow {
  width: 0.56rem;
  height: 5.68rem;
  background: #ffffff;
  box-shadow: 0px 2px 6px 0px rgba(219, 219, 219, 0.5);
  border: 1px solid #e7e7e7;
}
.cases-content .left {
  left: 0;
}
.cases-content .right {
  right: 0;
}
.left {
  position: absolute;
  left: 1.5%;
  top: 50%;
  transform: translateY(-50%);
}
.cases-video .left,
.cases-video .right {
  width: 0.4rem;
  height: 0.5rem;
}
.right {
  position: absolute;
  right: 1.5%;
  top: 50%;
  transform: translateY(-50%);
}
.arrow span {
  display: inline-block;
  border-top: 0.18rem solid transparent;
  border-bottom: 0.18rem solid transparent;
  border-left: 0.26rem solid transparent;
  border-right: 0.26rem solid #127bf2;
  position: absolute;
  right: 0.15rem;
}
.right span {
  display: inline-block;
  border-top: 0.18rem solid transparent;
  border-bottom: 0.18rem solid transparent;
  border-left: 0.26rem solid #127bf2;
  border-right: 0.26rem solid transparent;
  left: 0.15rem;
}
.intro-title {
  font-size: 0.23rem;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  margin: 0.3rem 0 0.16rem 0;
}
.intro-content {
  font-size: 0.16rem;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #bbbbbb;
  line-height: 0.35rem;
  margin: 0 0.37rem;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* 限制显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden; /* 隐藏溢出的文本 */
  text-overflow: ellipsis; /* 在文本溢出时显示省略号 */
  height: 0.7rem;
  margin-bottom: 0.2rem;
}
.intro-enter > div {
  width: 3.66rem;
  height: 0.52rem;
  background: #127bf2;
  border-radius: 0.26rem;
  line-height: 0.52rem;
  color: #fff;
}
.intro-enter > div:hover {
  background: #3692fc;
}
.intro-enter .btnactive {
  background: #3692fc;
}
</style>
